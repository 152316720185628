import { useEffect, useRef, useState } from 'react';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AudioSetting from './AudioSetting';

interface Props {
  attendeeId: number;
  participant: any;
  currentAutoFeed: string;
  currentAudioStatus: boolean;
  isApprovalPending: boolean;
  isReconnecting: boolean;
}
const VideoContainer = ({
  attendeeId,
  currentAutoFeed,
  participant,
  currentAudioStatus,
  isApprovalPending,
  isReconnecting
}: Props) => {
  const [isVideoTrackAvailable, setIsVideoTrackAvailable] = useState(true);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { primary, secondary } = participant;

  useEffect(() => {
    let videoTrackToAttach: RemoteVideoTrack | null = null;
    if (currentAutoFeed === 'primary') {
      videoTrackToAttach = primary.tracks?.find((track: RemoteVideoTrack) => {
        return (
          // @ts-ignore
          track?.kind === 'video' && track.name !== 'screenshare' && track.source !== 'screen_share'
        );
      });
    } else if (currentAutoFeed === 'secondary') {
      videoTrackToAttach = secondary.tracks?.find((track: RemoteVideoTrack) => {
        return track?.kind === 'video';
      });
    } else {
      videoTrackToAttach = primary.tracks?.find((track: RemoteVideoTrack) => {
        return (
          track?.kind === 'video' &&
          // @ts-ignore
          (track.name === 'screenshare' || track.source === 'screen_share')
        );
      });
    }

    if (videoTrackToAttach) {
      if (videoRef.current) {
        setIsVideoTrackAvailable(true);
        videoTrackToAttach.attach(videoRef.current);
      }
    } else {
      videoRef.current.srcObject = null;
      setIsVideoTrackAvailable(false);
    }
    return () => {
      if (videoTrackToAttach) {
        videoTrackToAttach.detach();
      }
    };
  }, [
    currentAutoFeed,
    primary.tracks,
    primary.tracks.length,
    secondary.tracks,
    secondary.tracks.length,
  ]);

  useEffect(() => {
    const audioTrack = primary.tracks.find((track: RemoteAudioTrack) => track.kind === 'audio');
    if (audioTrack) {
      setIsAudioAvailable(true);
      audioRef.current && audioTrack.attach(audioRef.current);
    } else {
      setIsAudioAvailable(false);
    }

    if (audioRef.current) {
      if (currentAudioStatus) {
        audioRef.current.volume = 0;
      } else {
        audioRef.current.volume = 1;
      }
    }

    return () => {
      if (audioTrack) {
        audioTrack.detach();
      }
    };
  }, [primary.tracks.length, currentAudioStatus]);

  return (
    <div
      data-testid="stream-video-container"
      className="relative flex flex-col items-center justify-center flex-grow h-full p-2"
    >
      <video className="w-full h-full" ref={videoRef} autoPlay muted playsInline></video>
      <audio data-testid="audio-element" ref={audioRef} autoPlay />
      {isAudioAvailable && (
        <AudioSetting currentAudioStatus={currentAudioStatus} attendeeId={attendeeId} />
      )}
      {!isVideoTrackAvailable && !isApprovalPending && !isReconnecting && (
        <div className="absolute flex flex-col items-center gap-1 text-sm text-white">
          <ExclamationTriangleIcon className="w-10 h-10" />
          <span>No Feed</span>
        </div>
      )}
    </div>
  );
};

export default VideoContainer;
