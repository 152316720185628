import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { SessionStatus } from 'globals/enums';

interface Props {
  isApprovalPending: boolean;
  isReconnecting: boolean;
  type: string;
}

const TileStatus = ({ type, isApprovalPending, isReconnecting }: Props) => {
  const renderStatus = () => {
    switch (type) {
      case SessionStatus.Completed:
        return (
          <div className="text-sm  absolute flex  z-10 items-center gap-1 rounded py-2 px-3 bg-green-500 text-white">
            <CheckCircleIcon className="h-5 w-5" />
            <span>Completed</span>
          </div>
        );
      case SessionStatus.Terminated:
        return (
          <div className="text-sm absolute flex  z-10 items-center gap-1 rounded py-2 px-3 bg-red-500 text-white">
            <XCircleIcon className="h-5 w-5" />
            <span>Terminated</span>
          </div>
        );
      default:
        return (
          <div className="text-sm absolute flex  z-10 flex-col gap-1 items-center text-white">
            <ExclamationTriangleIcon className="h-10 w-10" />
            <span>No Feed</span>
          </div>
        );
    }
  };

  return isApprovalPending ? (
    <div className="hover:opacity-0 cursor-default text-sm  flex items-center absolute z-10 gap-1 rounded py-2 px-3 bg-red-500 text-white">
      <ShieldExclamationIcon className="h-5 w-5" />
      <span>Approval Pending</span>
    </div>
  ) : isReconnecting ? (
    <div className="hover:opacity-0 cursor-default text-sm  flex items-center absolute z-10 gap-1 rounded py-2 px-3 bg-yellow-500 text-white">
      <ArrowPathIcon className="h-5 w-5" />
      <span>Reconnecting</span>
    </div>
  ) : (
    renderStatus()
  );
};

export default TileStatus;
