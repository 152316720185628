import Modal from './Modal';

function EnlargeView(props) {
  const { selectedGrab, showPreview, setShowPreview } = props;
  return (
    <Modal className="h-[70vh]" size="lg" show={showPreview} onCancel={() => setShowPreview(false)}>
      {selectedGrab && (
        <div className="flex flex-col py-4 align-middle h-full">
          <img
            className="w-full h-full object-contain inline-block m-1 transition-all"
            src={selectedGrab.img_src}
            alt="screen/camera grab"
          />
          <div className="text-xs text-center">{selectedGrab.timestamp}</div>
        </div>
      )}
    </Modal>
  );
}

export default EnlargeView;
