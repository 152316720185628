import { useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { MegaphoneIcon } from '@heroicons/react/24/outline';
import { closeModal } from 'common/slice/modalSlice';
import { ChatMessageRole } from 'globals/enums';
import { sendMessage } from 'helpers/messages';

interface Props {
  activeSessions: string[];
}

const Announcement = ({ activeSessions }: Props) => {
  const [inputText, setInputText] = useState('');
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    activeSessions.map(async (sessionId: string) => {
      await sendMessage({
        role: ChatMessageRole.Proctor,
        role_to: [ChatMessageRole.Alvy, ChatMessageRole.Candidate],
        text: inputText,
        session_id: Number(sessionId),
      });
    });
    setInputText('');
    dispatch(closeModal({}));
  };

  return (
    <div className="flex gap-2">
      <span className="w-10 h-10 bg-blue-200 text-blue-800 rounded-full flex p-2">
        <MegaphoneIcon />
      </span>
      <div className="flex-1 flex flex-col mt-1.5 gap-2">
        <p className="font-bold text-lg">Make Announcement</p>
        <p className=" text-zinc-500">
          You are broadcasting an announcement and you are free to choose from the preset list or
          you can have your custom message broadcaster as well.
        </p>
        <label htmlFor="customAnnouncement" className="text-zinc-500 mt-2 text-sm">
          Custom Announcements (if any)
        </label>
        <textarea
          id="customAnnouncement"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="p-2 rounded-md border w-full"
          cols={10}
          rows={3}
        ></textarea>
        <div className="mt-2 ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={inputText.length ? false : true}
            className="primary-btn"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
