import { useEffect, useRef, useState } from 'react';
import { findLastIndex, get } from 'lodash';
import { useAppSelector } from 'app/hooks';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import Alvy from 'assets/alvy.png';
import { chatActionIconMap, USER_ROLES } from 'common/components/constant';
import { getDeliveredStatusWithDateTime, sendMessage } from 'helpers/messages';
import { Message } from 'globals/interfaces';
import { AlvyMessageStatus, ChatMessageActions, ChatMessageRole } from 'globals/enums';

type Props = {
  chatMessages: Message[];
  searchQuery?: string;
  searchedMessages?: Message[];
  selectedMessageIndex?: number | null;
  session_id?: number;
  handleEditMessage?: (messageText: string, session_id: number, messageKey: string) => Promise<void>;
};

const ChatMessages = ({
  chatMessages,
  searchQuery,
  selectedMessageIndex,
  searchedMessages,
  session_id,
  handleEditMessage,
}: Props) => {
  const groupedMessages = [];
  let currentUserRole = null;

  const [showMessageId, setShowMessageId] = useState('');
  const conversationRef = useRef<HTMLDivElement>();
  const currentTimeStamp = useAppSelector((state) => state.playbackVideo.currentPlayingTime);
  const sasToken = useAppSelector(state => state.session.token)[session_id]?.value;

  const handleAction = async (
    actionType: ChatMessageActions,
    messageText: string,
    messageKey: string,
  ) => {

    if (actionType === ChatMessageActions.Notify) {
      return await sendMessage(
        {
          role: ChatMessageRole.Proctor,
          role_to: [ChatMessageRole.Candidate, ChatMessageRole.Alvy],
          text: messageText,
          session_id: session_id,
        },
        AlvyMessageStatus.Accepted,
        messageKey,
      );
    }

    if (actionType === ChatMessageActions.Edit) {
      return await handleEditMessage(messageText, session_id, messageKey);
    }
  };

  useEffect(() => {
    if (currentTimeStamp) {
      const activeAlertIndex = findLastIndex(chatMessages, (message) => {
        return message.created_at < currentTimeStamp;
      });
      // @ts-ignore
      const chatElement: HTMLElement = conversationRef?.current?.childNodes[activeAlertIndex];
      chatElement?.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'start',
      });
    }
  }, [currentTimeStamp]);

  useEffect(() => {
    if (searchedMessages?.length && selectedMessageIndex !== null) {
      const selectedMessage = get(searchedMessages[selectedMessageIndex], 'occurrences', 0);
      if (selectedMessage > 0) {
        const messageToScrollTo = searchedMessages[selectedMessageIndex];
        const selectedMessageElement = conversationRef.current?.querySelector(
          `[data-message-id="${get(messageToScrollTo, 'id')}"]`,
        );
        if (selectedMessageElement instanceof HTMLElement) {
          selectedMessageElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
            block: 'start',
          });
        }
      }
    }
  }, [searchedMessages, selectedMessageIndex]);

  const escapeRegExp = (str) => {
    return str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const renderHtmlContent = (message: string) => {
    const containsHtmlTags = /<[^>]+>/g.test(message);
    if (containsHtmlTags) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const replaceTextNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const regex = new RegExp(`(${escapeRegExp(searchQuery)})`, 'gi');
          const replacedText = node.textContent?.replace(
            regex,
            `<span class="text-blue-500 bg-white">$1</span>`,
          );
          if (replacedText) {
            const span = document.createElement('span');
            span.innerHTML = replacedText;
            node.parentNode?.replaceChild(span, node);
          }
        } else {
          node.childNodes.forEach(replaceTextNodes);
        }
      };

      doc.body.childNodes.forEach(replaceTextNodes);

      return (
        <div className="chat-style" dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }}></div>
      );
    } else {
      const regex = new RegExp(`(${escapeRegExp(searchQuery)})`, 'gi');
      const replacedText = message.replace(regex, `<span class="text-blue-500 bg-white">$1</span>`);
      return <div className="chat-style" dangerouslySetInnerHTML={{ __html: replacedText }}></div>;
    }
  };

  chatMessages.forEach((message: Message) => {
    if (currentUserRole !== message.role) {
      groupedMessages.push({
        role: message.role,
        messages: [message],
        created_at: message.created_at,
      });
      currentUserRole = message.role;
    } else {
      groupedMessages[groupedMessages.length - 1].messages.push(message);
    }
  });

  return (
    <div className="flex flex-col m-2" ref={conversationRef}>
      {groupedMessages.map((group, index) => (
        <div
          key={index}
          className={`flex ${
            group.role === USER_ROLES.CANDIDATE || !group.role ? 'justify-start' : 'justify-end'
          }`}
        >
          <div className="flex w-full gap-2 mb-2">
            <div
              className={`${
                group.role === USER_ROLES.CANDIDATE || !group.role ? 'order-first' : 'order-last'
              }`}
            >
              <span
                className={`flex justify-center items-center h-6 w-6  text-xs rounded-full border shadow border-solid ${
                  group.role === USER_ROLES.CANDIDATE || !group.role
                    ? 'bg-white'
                    : 'bg-orange-500 text-white'
                }`}
              >
                {group.role === USER_ROLES.CANDIDATE || !group.role ? (
                  'C'
                ) : group.role === USER_ROLES.ALVY ? (
                  <img src={Alvy} alt="alvy icon" />
                ) : (
                  'P'
                )}
              </span>
            </div>
            <div className="flex flex-col w-full overflow-hidden">
              {group.messages.map((message: Message, i: number) => (
                <div
                  key={`${i}_msg`}
                  data-message-id={message.id}
                  className={`${
                    group.role === USER_ROLES.CANDIDATE || !group.role
                      ? 'justify-start'
                      : 'justify-end'
                  } flex flex-col w-full mb-6`}
                >
                  <div
                    className={`${
                      group.role === USER_ROLES.CANDIDATE || !group.role
                        ? 'justify-start'
                        : 'justify-end'
                    } inline-flex flex-wrap`}
                  >
                    {message.type === 'recording' ? (
                      <audio
                        controls
                        controlsList="nodownload"
                        src={message.url}
                        onError={(event) => {
                          const audioElement = event.target as HTMLMediaElement;
                          audioElement.load();
                        }}
                      ></audio>
                    ) : (
                      <div
                        className={
                          message.status && showMessageId !== message.id
                            ? 'hidden'
                            : 'block overflow-auto'
                        }
                      >
                        <div
                          className={`${
                            group.role === USER_ROLES.CANDIDATE || !group.role
                              ? 'bg-white'
                              : group.role === USER_ROLES.ALVY
                              ? 'bg-gradient-to-r from-blue-500 to-cyan-500 text-white'
                              : 'bg-blue-500 text-white'
                          } shadow p-3 border-gray-300 max-w-full rounded-lg whitespace-normal overflow-auto mb-1.5`}
                        >
                          <div data-testid="message-text" className="text-sm">
                            {message?.text && renderHtmlContent(message.text)}
                            {message?.image_url && (
                              <img
                                loading="lazy"
                                src={
                                  sasToken
                                    ? message?.image_url.split('?')[0].concat(`?${sasToken}`)
                                    : message?.image_url
                                }
                                alt="Candidate screenshot"
                              />
                            )}
                          </div>
                        </div>
                        {message.actions && !message.status && (
                          <div className="py-2 px-1 w-full">
                            <div className="flex justify-end gap-1">
                              {message.actions.map((actionType: ChatMessageActions) => {
                                const Icon = chatActionIconMap[actionType];
                                return (
                                  <button
                                    key={`${message.key}-${actionType}`}
                                    onClick={() =>
                                      handleAction(actionType, message.text, message.key)
                                    }
                                    title={actionType}
                                    className="p-2 rounded-full bg-white border shadow-sm"
                                  >
                                    <Icon className="w-4 h-4 text-blue-500 " />
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {message.status && (
                    <button
                      onClick={() => setShowMessageId(showMessageId ? '' : message.id)}
                      className={`flex gap-1.5 items-center text-blue-500 text-xs ${
                        group.role === USER_ROLES.CANDIDATE || !group.role
                          ? 'justify-start'
                          : 'justify-end'
                      }`}
                    >
                      {showMessageId ? (
                        <EyeSlashIcon className="w-4 h-4" />
                      ) : (
                        <EyeIcon className="w-4 h-4" />
                      )}
                      <span className="capitalize">
                        {showMessageId ? 'Hide' : 'View'} {message.status} Response
                      </span>
                    </button>
                  )}
                  <div
                    className={`flex text-8 text-gray-500 mt-0.5 ${
                      group.role === USER_ROLES.CANDIDATE || !group.role
                        ? 'justify-start'
                        : 'justify-end'
                    } leading-3`}
                  >
                    <span> {getDeliveredStatusWithDateTime(message)} </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessages;
