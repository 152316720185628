import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { equalTo, getDatabase, off, onValue, orderByChild, query, ref } from 'firebase/database';
import store from 'app/store';
import { startSession, updateSession } from 'common/slice/sessionListSlice';
import { Session } from 'globals/interfaces';

const sessionListener = createListenerMiddleware();
const streamingDeviceListeners = new Map<string, () => void>();
const setupStreamingDeviceListener = (db: ReturnType<typeof getDatabase>, session: Session) => {
  const { session_id } = session;

  const disabledRef = ref(
    db,
    `session/${session_id}/streaming_device/is_secondary_disabled`
  );
  if (streamingDeviceListeners.has(session_id)) {
    off(disabledRef);
    streamingDeviceListeners.delete(session_id);
  }
  onValue(disabledRef, (disabledSnapshot) => {
    const newValue = disabledSnapshot.val();
    store.dispatch((dispatch, getState) => {
      const currentSessions: Session[] = getState().sessionList.sessions;
      const currentSession = currentSessions.find((s) => s.session_id === session_id);

      if (currentSession?.is_secondary_disabled !== newValue) {
        const updatedSessions = currentSessions.map((s) =>
          s.session_id === session_id ? { ...s, is_secondary_disabled: newValue } : s
        );
        dispatch(updateSession(updatedSessions));
      }
    });
  });

  streamingDeviceListeners.set(session_id, () => off(disabledRef));
};

sessionListener.startListening({
  matcher: isAnyOf(startSession),
  effect: async (action) => {
    if (!startSession.match(action)) return;

    const participantGroupId = action.payload;
    const db = getDatabase();

    const sessionsQuery = query(
      ref(db, 'session_v2'),
      orderByChild('participant_group_id'),
      equalTo(participantGroupId),
    );

    onValue(sessionsQuery, (snapshot) => {
      const data = snapshot.val();
      const sessionData: Session[] = data
        ? Object.keys(data).map((key) => ({
            ...data[key],
            session_id: key,
          }))
        : [];

      store.dispatch(updateSession(sessionData));

      sessionData.forEach((session) => {
        if (session.configuration?.secondary_camera) {
          setupStreamingDeviceListener(db, session);
        }
      });
    });
  },
});

export default sessionListener;
