import { useRef, useEffect, memo } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { useSendMessageMutation } from 'common/api/message';
import { handleError } from 'helpers/sentry';
import { markCandidateMessagesAsReadInFirebase, updateAlvyMessageStatus } from 'helpers/messages';
import { isSessionEnded } from 'helpers/session';
import { Message, Messages } from 'globals/interfaces';
import ChatMessages from './ChatMessages';
import BundledEditor from 'components/editor/Editor';
import { TINYMCE_LICENSE_KEY } from 'common/components/constant';
import { AlvyMessageStatus, ChatMessageRole } from 'globals/enums';

interface Props {
  messages: Messages;
  session_id: string;
  session_status: string;
  searchQuery?: string;
  searchedMessages?: Message[];
}

const ChatWindow = memo(({ messages, session_id, session_status }: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [sendMessage, { isLoading: isSendingMessage }] = useSendMessageMutation();

  const isSessionOver = isSessionEnded(session_status);
  const chatMessages = messages
    ? Object.keys(messages)
        .map((key) => ({ ...messages[key], key }))
        .filter((message) => message.role !== ChatMessageRole.System)
    : [];

  const handleSendMessage = async () => {
    const inputText = editorRef.current?.getContent();
    if (inputText?.trim() !== '') {
      try {
        const messageData = {
          role: ChatMessageRole.Proctor,
          role_to: [ChatMessageRole.Candidate, ChatMessageRole.Alvy],
          text: inputText,
          session_id: Number(session_id),
        };
        editorRef.current.setContent('');
        await sendMessage(messageData).unwrap();
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleEditMessage = async (messageText: string, session_id: number, messageKey: string) => {
    try {
      editorRef.current.setContent(messageText);
      await updateAlvyMessageStatus(session_id, messageKey, AlvyMessageStatus.Updated);
    } catch (error) {
      handleError(error);
    }
  };

  const messagesUpdate = () => {
    if (messages && messagesEndRef.current) {
      markCandidateMessagesAsReadInFirebase(session_id, messages);
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 0);
    }
  };

  useEffect(messagesUpdate, [chatMessages?.length]);

  const editorRef = useRef(null);

  return (
    <>
      <div className="flex flex-col flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100%)' }}>
        <ChatMessages
          session_id={Number(session_id)}
          chatMessages={chatMessages}
          handleEditMessage={handleEditMessage}
        />
        <div data-testid="messages-end-ref" ref={messagesEndRef}></div>
      </div>
      <div className="flex items-center justify-center gap-1">
        <BundledEditor
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: 300,
            menubar: false,
            plugins: ['link', 'lists', 'autolink'],
            toolbar:
              'bold italic underline| \
            bullist numlist',
            license_key: TINYMCE_LICENSE_KEY,
          }}
          key={session_id}
          content_style="text-gray-600"
          handleEnter={handleSendMessage}
          disabled={isSessionOver || isSendingMessage}
        />
        <button
          data-testid="send-message"
          disabled={isSessionOver}
          className="h-full flex items-center bg-white border px-2.5 rounded-md"
        >
          <PaperAirplaneIcon
            className={`w-5 h-5 cursor-pointer ${
              isSessionOver ? 'fill-gray-300' : 'text-blue-700'
            }`}
            onClick={handleSendMessage}
          />
        </button>
      </div>
    </>
  );
});

export default ChatWindow;
