import { XCircleIcon } from '@heroicons/react/24/outline';
import { defaultAlertTypes } from 'common/components/constant';
import { Alert } from 'globals/interfaces';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'helpers/date';

interface Props {
  alert: Alert;
  tooltipId: string;
  isEmbeddedView: boolean
  handleDismissFlag: (alertId: string) => void;
}

const AlertStatus = ({ alert, tooltipId, isEmbeddedView, handleDismissFlag }: Props) => {
  const { created_at, created_by, deleted, suspend_reason, id } = alert;

  const showDismissButton = (alert) => {
    return (
      alert.classification !== 'debug' &&
      !defaultAlertTypes.includes(alert.alert_type_id) &&
      !isEmbeddedView
    );
  };

  return deleted ? (
    <>
      <div className="text-xs rounded p-1 text-red-500 bg-red-100" data-tooltip-id={tooltipId}>
        Rejected
      </div>
      <Tooltip id={tooltipId} place="top">
        <div className="text-xs flex flex-col gap-1">
          <p className="text-xs">{suspend_reason}</p>
        </div>
      </Tooltip>
    </>
  ) : (
    <>
      {created_by && (
        <>
          <div
            className="px-[8px] py-[3px] bg-green-100 text-green-800 text-xs rounded-full"
            data-tooltip-id={tooltipId}
          >
            Added
          </div>
          <Tooltip id={tooltipId} place="top">
            {formatDate(created_at)}
          </Tooltip>
        </>
      )}
      {showDismissButton(alert) && (
        <button
          data-testid="dismiss-btn"
          className="w-[24px] ml-2"
          onClick={() => handleDismissFlag(id)}
        >
          <XCircleIcon className="h-[24px]  w-[24px] cursor-pointer" />
        </button>
      )}
    </>
  );
};

export default AlertStatus;
