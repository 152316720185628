import { getDatabase, ref, runTransaction } from 'firebase/database';

export const extendSessionInFirebase = async (totalExtendedDuration: number, sessionId: string): Promise<boolean> => {
    const database = getDatabase();
    const sessionRef = ref(database, `session_v2/${sessionId}/extend`);
    return runTransaction(sessionRef, (data) => {
        if(data === null) {
            return { totalExtendedDuration: totalExtendedDuration };
        }
        const oldDuration = data.totalExtendedDuration || 0;
        return { totalExtendedDuration: oldDuration + totalExtendedDuration };
    })
    .then(() => {
        return true;
    })
    .catch((error) => {
        console.error("Error extending session: ", error);
        return false;
    });
};