import { useAppDispatch } from 'app/hooks';
import { useState } from 'react';
import { closeModal } from 'common/slice/modalSlice';
import { toggleDisableSecondaryCameraInFirebase } from 'helpers/secondaryCamera';
import { AlertTypes, ChatMessageRole } from 'globals/enums';
import { useAddAlertMutation } from 'common/api/alert';
import { useSendMessageMutation } from 'common/api/message';
import { handleError } from 'helpers/sentry';

interface Props {
  sessionId: string;
  sessionUuid: string;
}

const SecondaryDisableModal = ({ sessionId, sessionUuid }: Props) => {
  const [disabledReason, setDisabledReason] = useState('');
  const dispatch = useAppDispatch();
  const [sendMessage] = useSendMessageMutation();
  const [addAlert] = useAddAlertMutation();

  const disableSecondaryCamera = async () => {
    dispatch(closeModal({}));
    toggleDisableSecondaryCameraInFirebase(sessionId, true);
    addSecondaryCameraDisabledAlert();
    sendChatMessage();
  };

  const addSecondaryCameraDisabledAlert = async () => {
    await addAlert({
        alert_type_id: AlertTypes.SecondaryCameraSkipped,
        description: `Reason: ${disabledReason}`,
        session_id: sessionUuid,
        timestamp: new Date().toISOString(),
    });
  };

  const sendChatMessage = async () => {
    try {
      const messageData = {
        role: ChatMessageRole.Proctor,
        role_to: [ChatMessageRole.Alvy, ChatMessageRole.Candidate],
        text: 'Secondary camera is disabled for your session. You can proceed ahead with the next steps.',
        session_id: Number(sessionId),
      };
      await sendMessage(messageData).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg">Skip Secondary Camera Setup?</p>
        <p className="mb-2 text-zinc-500 mt-5">Disabling the secondary camera setup will allow the candidate to proceed without using their mobile device as an additional camera. This may reduce the level of security during the exam.
        Are you sure you want to proceed?</p>
        <div className='flex flex-row mt-5'>
          <label htmlFor="disabledComments" className="flex flex-row text-zinc-500">
            Reason
          </label>
          <textarea
            id="disabledComments"
            value={disabledReason}
            onChange={(e) => setDisabledReason(e.target.value)}
            className="ml-2 p-2 rounded-md border w-60"
            placeholder="Add text"
            cols={10}
            rows={3}
          ></textarea>
        </div>
        <div className="mt-3 ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={!(disabledReason && disabledReason.length > 0)}
            className="primary-btn"
            onClick={disableSecondaryCamera}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondaryDisableModal;
