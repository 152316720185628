import { useAppDispatch } from 'app/hooks';
import { useState } from 'react';
import { closeModal } from 'common/slice/modalSlice';
import { toggleDisableSecondaryCameraInFirebase } from 'helpers/secondaryCamera';
import { AlertTypes } from 'globals/enums';
import { useAddAlertMutation } from 'common/api/alert';

interface Props {
  sessionId: string;
  sessionUuid: string;
}

const SecondaryEnableModal = ({ sessionId, sessionUuid }: Props) => {
  const [enabledReason, setEnabledReason] = useState('');
  const dispatch = useAppDispatch();
  const [addAlert] = useAddAlertMutation();

  const enableSecondaryCamera = async () => {
    dispatch(closeModal({}));
    toggleDisableSecondaryCameraInFirebase(sessionId, false);
    addSecondaryCameraEnabledAlert();
  };

  const addSecondaryCameraEnabledAlert = async () => {
    await addAlert({
        alert_type_id: AlertTypes.SecondaryCameraEnabled,
        description: `Reason: ${enabledReason}`,
        session_id: sessionUuid,
        timestamp: new Date().toISOString(),
    });
  };

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg">Enable Secondary Camera Setup?</p>
        <p className="mb-2 text-zinc-500 mt-5">Enabling this will require the candidate to complete the setup before proceeding with starting the exam.
        Are you sure you want to proceed?</p>
        <div className='flex flex-row mt-5'>
          <label htmlFor="enabledComments" className="flex flex-row text-zinc-500">
            Reason
          </label>
          <textarea
            id="enabledComments"
            value={enabledReason}
            onChange={(e) => setEnabledReason(e.target.value)}
            className="ml-2 p-2 rounded-md border w-60"
            placeholder="Add text"
            cols={10}
            rows={3}
          ></textarea>
        </div>
        <div className="mt-3 ml-auto flex gap-1 text-sm">
          <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
            Cancel
          </button>
          <button
            disabled={!(enabledReason && enabledReason.length > 0)}
            className="primary-btn"
            onClick={enableSecondaryCamera}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondaryEnableModal;
