import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { ChatMessageRole } from "globals/enums";
import baseQueryWithReauth from "./base";

export interface SendMessagePayload {
  role_to: ChatMessageRole[];
  role: ChatMessageRole;
  text: string;
  session_id: number;
}

export const chatMessageApi = createApi({
  reducerPath: "chatMessage",
  baseQuery: retry(baseQueryWithReauth),
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (payload: SendMessagePayload) => {
        return {
          url: "v1/message/send",
          method: "POST",
          body: payload,
        };
      },
      extraOptions: { maxRetries: 3 }
    }),
  }),
  
});

export const { useSendMessageMutation } = chatMessageApi;
