import { useState } from 'react';

import { Tooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeModal } from 'common/slice/modalSlice';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useUpdateProviewIndexMutation } from 'common/api/post-session-embed';
import { AlertSeverity } from 'globals/enums';
import { setUpdateproviewRatingStale } from 'common/slice/playbackVideoSlice';

import { PREDEFINED_RATING_MODIFIED_REASONS } from 'common/components/constant';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const EditProviewIndex = () => {
  const dispatch = useAppDispatch();
  const [newRating, setNewRating] = useState('');
  const [predefinedReason, setPredefinedReason] = useState('');
  const [overrideReason, setOverrideReason] = useState('');
  const { sessionId, attendeeId } = useAppSelector((state) => state.playbackVideo);
  const [updateProviewIndex] = useUpdateProviewIndexMutation({});

  const handleProviewIndexUpdate = async () => {
    if (attendeeId && sessionId) {
      await updateProviewIndex({
        attendeeId,
        newRating,
        overrideReason,
        predefinedReason,
        sessionId,
      });
      dispatch(setUpdateproviewRatingStale(false));
    }
    dispatch(closeModal({}));
  };

  return (
    <>
      <div className="flex gap-3">
        <span className="flex w-10 h-10 p-2 text-blue-900 bg-blue-200 rounded-full">
          <PencilSquareIcon className="text-blue-900" />
        </span>
        <div className="flex-1 flex flex-col mt-1.5 w-full">
          <p className="text-lg font-bold">Edit Proctor Index</p>
        </div>
      </div>
      <div>
        <div className="flex justify-between items-center mt-2 mb-9">
          <label className="col-span-1 text-right">Index </label>
          <span className="w-[320px]">
            <select
              onChange={(e) => setNewRating(e.target.value)}
              className="w-full col-span-4 p-2 border rounded-md"
              defaultValue=""
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value={AlertSeverity.High}>High</option>
              <option value={AlertSeverity.Medium}>Medium</option>
              <option value={AlertSeverity.Low}>Low</option>
            </select>
          </span>
        </div>
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center cursor-pointer">
            <label className="text-right flex items-center">Reason</label>
          </div>
          <span className="w-[320px]">
            <select
              onChange={(e) => setPredefinedReason(e.target.value)}
              className="w-full col-span-4 p-2 border rounded-md"
              defaultValue=""
            >
              <option value="" disabled>
                Select an option
              </option>
              {PREDEFINED_RATING_MODIFIED_REASONS.map((reason) => (
                <option title={reason.description} key={reason.key} value={reason.value}>
                  {reason.value}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="w-full h-fit flex items-center justify-end">
          <textarea
            disabled={!predefinedReason}
            placeholder="Remarks"
            id="terminateComments"
            value={overrideReason}
            onChange={(e) => setOverrideReason(e.target.value)}
            className="w-[350px] p-2 border rounded-md resize-none leading-6 text-left placeholder:text-center placeholder:leading-[60px]"
            cols={10}
            rows={3}
          ></textarea>
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 mt-6 ml-auto text-sm">
        <button className="secondary-btn" onClick={() => dispatch(closeModal({}))}>
          Cancel
        </button>
        <button
          disabled={!newRating || !predefinedReason}
          className="p-2 text-white bg-blue-500 rounded enabled:hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-70"
          onClick={handleProviewIndexUpdate}
        >
          Save changes
        </button>
      </div>
    </>
  );
};

export default EditProviewIndex;
