import { closeModal } from 'common/slice/modalSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Modal from './Modal';
import NoShowModal from 'components/noshow/NoShowModal';
import Terminate from 'components/terminate/Terminate';
import { SessionStatus } from 'globals/enums';
import AddFlag from 'components/alerts/AddFlag';
import DismissFlag from 'components/alerts/DismissFlag';
import Announcement from 'components/announcement/Announcement';
import EditProctorIndex from 'components/summary/EditProviewIndex';
import Pause from 'components/pause/Pause';
import Resume from 'components/resume/Resume';
import Extend from 'components/extend/Extend';
import SecondaryCameraDisable from 'components/secondary-camera/SecondaryCameraDisable';
import SecondaryCameraEnable from 'components/secondary-camera/SecondaryCameraEnable';

const ActionModal = () => {
  const dispatch = useAppDispatch();
  const {
    type,
    isOpen,
    slotEndDate,
    slotStartDate,
    attendeeId,
    sessionUuid,
    sessionId,
    alertId,
    activeSessions,
    sessionJoinedAt,
    sessionCompletedAt,
  } = useAppSelector((state) => state.modal);
  const renderMessage = () => {
    switch (type) {
      case SessionStatus.NoShow:
        return <NoShowModal attendeeId={attendeeId} />;
      case SessionStatus.Terminated:
        return <Terminate sessionId={sessionId} />;
      case 'add-flag':
        return (
          <AddFlag
            sessionUuid={sessionUuid}
            slotStartDate={sessionJoinedAt ? sessionJoinedAt : slotStartDate}
            slotEndDate={sessionCompletedAt ? sessionCompletedAt : slotEndDate}
          />
        );
      case 'dismiss-flag':
        return <DismissFlag sessionUuid={sessionUuid} alertId={alertId} />;
      case 'announcement':
        return <Announcement activeSessions={activeSessions} />;
      case 'proview-index':
        return <EditProctorIndex />;
      case 'pause':
        return <Pause sessionId={sessionId} />;
      case 'resume':
        return <Resume sessionId={sessionId} />;
      case 'extend':
        return <Extend sessionId={sessionId} sessionUuid={sessionUuid}/>;
      case 'disable-secondary-camera':
        return <SecondaryCameraDisable sessionId={sessionId} sessionUuid={sessionUuid}/>;
      case 'enable-secondary-camera':
        return <SecondaryCameraEnable sessionId={sessionId} sessionUuid={sessionUuid}/>;
      default:
        break;
    }
  };

  return (
    <Modal show={isOpen} onCancel={() => dispatch(closeModal({}))}>
      {renderMessage()}
    </Modal>
  );
};

export default ActionModal;
