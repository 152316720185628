import Modal from './Modal';

type OfflineVideoPreviewProps = {
  url: string,
  onClose: () => void;
}

const OfflineVideoPreview = ({ url, onClose }: OfflineVideoPreviewProps) => {
  return (
    <Modal data-testid="offline-video-preview" size="lg" show={Boolean(url)} onCancel={onClose}>
      <div className="py-5 px-2">
        <video src={url} className="w-full h-full bg-black max-h-[500px]" controls />
      </div>
    </Modal>
  );
};

export default OfflineVideoPreview;
