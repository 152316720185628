import { useAppDispatch } from 'app/hooks';
import Button from 'common/components/Button';
import { closeModal } from 'common/slice/modalSlice';
import { toggleSessionPausedInFirebase } from 'helpers/sessionPause';
import { Mixpanel } from '../../Mixpanel';
import { ChatMessageRole } from 'globals/enums';
import { sendMessage } from 'helpers/messages';

interface Props {
  sessionId: string;
}

const PauseModal = ({ sessionId }: Props) => {
  const dispatch = useAppDispatch();

  const updatePauseStatusinFirebase = async () => {
    dispatch(closeModal({}));
    toggleSessionPausedInFirebase(sessionId, true);
    sendChatMessage();
    Mixpanel.track("proctoring_session_paused");
  };

  const sendChatMessage = async () => {
    const messageData = {
      role: ChatMessageRole.Proctor,
      role_to: [ChatMessageRole.Alvy, ChatMessageRole.Candidate],
      text: 'Session paused: Your session is currently paused by the proctor. Please wait until the proctor resumes your session.',
      session_id: Number(sessionId),
    };
    await sendMessage(messageData);
  };

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col mt-1.5">
        <p className="font-bold text-lg text-center">Pause session</p>
        <p className="mb-2 text-zinc-500">Are you sure you want to pause this session? The candidate will be notified, and the session will pause.<br />
          Note: This action only prevents the student from accessing the exam window and does not pause the exam timer in the application. If the timer runs out, the exam will end automatically.</p>
        <div className="mt-3 flex text-sm justify-between">
          <Button
            label="Cancel"
            className="secondary-btn"
            onClick={() => dispatch(closeModal({}))}
          />
          <Button
            label="Confirm"
            className="primary-btn"
            onClick={updatePauseStatusinFirebase}
          />
        </div>
      </div>
    </div>
  );
};

export default PauseModal;
